import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import whatWeOfferData from "../../../ComponentAPI/whatWeOfferAPI";
import "./WhatWeOffer.scss";

const WhatWeOffer = (props) => (
  <section className="what_we_offer_sec sec_pt sec_pb gradient_bg">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-12">
          <div className="heading_wrapper mb-5 mb-md-0">
            <h2 className="sec_heading sec_heading-medium with_border">
              {props.title}
            </h2>
          </div>
        </div>
        <div className="col-md-8 col-12">
          <div className="what_we_offer_content_wrapper">
            <Tabs id="uncontrolled-tab-example" className="mb-3">
              {whatWeOfferData.map((val, index) => {
                return (
                  <Tab
                    eventKey={"offer-tab-" + index}
                    key={index}
                    title={val.title}
                  >
                    <div className="tab_content">
                      <p>{val.description}</p>
                      <ul>
                        {val.detailList.map((val, index) => {
                          return <li key={index}>{val.listItem}</li>;
                        })}
                      </ul>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default WhatWeOffer;
