import React from "react";
import Slider from "react-slick";
import wordsClientData from "../../ComponentAPI/wordsClientAPI";
import "./WordsFromClients.scss";

const WordsFromClients = (props) => {
  const clientSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    touchThreshold: 30,
    centerPadding: "100px",
    centerMode: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "10px",
          centerMode: true,
        },
      },
    ],
  };

  const secClassNames = props.secBg
    ? `${"words_from_client_sec sec_pt sec_pb ".concat(props.secBg)}`
    : `words_from_client_sec ${!props.isTopPadding ? "" : "sec_pt"} sec_pb `;

  return (
    <section className={secClassNames}>
      <div className="container">
        <div className="heading_wrapper mb-5">
          <h2 className="sec_heading sec_heading-medium with_border">
            {props.title}
          </h2>
        </div>
      </div>

      <div className="words_from_client_slider_wrapper">
        <Slider {...clientSettings}>
          {wordsClientData.map((val, index) => {
            return (
              <div className="item" key={index}>
                <div className="words_from_client_content_wrapper">
                  <div className="words_from_client-details">
                    <p>{val.description}</p>
                  </div>
                  <div className="words_from_client-bottom-text">
                    <p className="words_from_client-name">{val.clientName}</p>
                    <p className="words_from_client-designation">
                      {val.clientOccupation}
                    </p>
                  </div>
                  <div className="words_from_client-img">
                    <img
                      src={val.clientImg}
                      alt=""
                      className="img-fluid"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default WordsFromClients;
