import React from "react";
import WorkCard from "./WorkCard/WorkCard";
import "./work.scss";

const Work = (props) => (
  <section className="work">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="heading_wrapper ">
            <h2 className="sec_heading sec_heading-medium with_border">
              {props.title}
            </h2>
          </div>
        </div>

        <div className="col-lg-10 offset-lg-1">
          <WorkCard></WorkCard>
        </div>
      </div>
    </div>
  </section>
);

export default Work;
