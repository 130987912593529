import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import "./App.scss";
import Footer from "./component/Footer";
import Header from "./component/Header";
import ScrollToTop from "./component/ScrollToTop";
import Home from "./pages/Home";

const PageNotFound = lazy(() => import("./component/PageNotFound"));
const About = lazy(() => import("./pages/About"));
const Blog = lazy(() => import("./pages/Blog"));
const BlogDetail = lazy(() => import("./pages/Blog/BlogDetails"));
const Career = lazy(() => import("./pages/Careers"));
const CareerDetails = lazy(() => import("./pages/Careers/CareerDetails"));
const JobDetails = lazy(() => import("./pages/Career/JobDetails"));
const Contact = lazy(() => import("./pages/Contact"));
const CTAForm = lazy(() => import("./pages/CTAForm"));
const CloudSolutions = lazy(() =>
  import("./pages/Services/CloudSolutionsServices")
);
const CustomApplication = lazy(() =>
  import("./pages/Services/CustomApplicationDevelopment")
);
const DataScrapingAnalysis = lazy(() =>
  import("./pages/Services/DataAnalysis")
);
const ECommerceDevelopment = lazy(() =>
  import("./pages/Services/EcomDevelopment")
);
const OffShoreDevelopment = lazy(() =>
  import("./pages/Services/OffShoreDevelopmentServices")
);
const QualityAssurance = lazy(() => import("./pages/Services/QaServices"));
const SassSolutions = lazy(() => import("./pages/Services/SaasDevelopment"));
const Seo = lazy(() => import("./pages/Services/SeoServices"));
const Services = lazy(() => import("./pages/Services"));

const ShopifyDevelopment = lazy(() =>
  import("./pages/Services/ShopifyDevelopment")
);

const SaasCloud = lazy(() => import("./pages/Services/SaasDevelopment"));
const TeamEngagementShopify = lazy(() =>
  import("./pages/Services/TeamEngagementModel")
);

const SoftwareMigration = lazy(() =>
  import("./pages/Services/SoftwareMigrationServices")
);
const UiUxDevelopment = lazy(() =>
  import("./pages/Services/UiUxDevelopmentServices")
);
const WebDevelopment = lazy(() =>
  import("./pages/Services/WebDevelopmentServices")
);
const WordPressDevelopment = lazy(() =>
  import("./pages/Services/WordPressDevelopment")
);
const LaravelDevelopment = lazy(() =>
  import("./pages/Technologies/LaravelDevelopment")
);
const FrontEndTech = lazy(() => import("./pages/Technologies/FrontEndTech"));
const TechnologiesDetails = lazy(() =>
  import("./pages/Technologies/TechnologiesDetails")
);
const TechnologiesNew = lazy(() =>
  import("./pages/Technologies/TechnologiesNew")
);
const PhpDevelopment = lazy(() =>
  import("./pages/Technologies/PhpDevelopment")
);
const WhyUs = lazy(() => import("./pages/WhyUs/WhyUs"));
const CaseStudy = lazy(() => import("./pages/CaseStudy/CaseStudy"));
const MyByk = lazy(() => import("./pages/CaseStudy/MyByk"));
const HireUs = lazy(() => import("./pages/HireUs"));

const App = () => {
  return (
    <Suspense fallback={<h1 className="loading-title">Loading...</h1>}>
      <main>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<BlogDetail />} />
          <Route path="services" element={<Services />} />
          <Route
            path="services/web-development-services"
            element={<WebDevelopment />}
          />
          <Route
            path="services/shopify-enterprise-services"
            element={<ShopifyDevelopment />}
          ></Route>
          <Route
            path="services/wordpress-enterprise-services"
            element={<WordPressDevelopment />}
          ></Route>
          <Route
            path="technologies/laravel-services"
            element={<LaravelDevelopment />}
          ></Route>
          <Route
            path="technologies/php-development"
            element={<PhpDevelopment />}
          ></Route>
          <Route path="services/saas-solutions" element={<SaasCloud />}></Route>
          <Route
            path="services/:id"
            element={<TeamEngagementShopify />}
          ></Route>

          <Route
            path="services/ui-ux-development"
            element={<UiUxDevelopment />}
          />
          <Route
            path="services/ecommerce-development"
            element={<ECommerceDevelopment />}
          />
          <Route
            path="services/data-analysis"
            element={<DataScrapingAnalysis />}
          />
          <Route path="services/seo" element={<Seo />} />
          <Route
            path="services/software-migration"
            element={<SoftwareMigration />}
          />
          <Route path="services/qa-services" element={<QualityAssurance />} />
          <Route path="services/cloud-solutions" element={<CloudSolutions />} />
          <Route
            path="services/offshore-development-center"
            element={<OffShoreDevelopment />}
          />
          <Route
            path="services/custom-application-development"
            element={<CustomApplication />}
          />
          <Route path="services/saas-solutions" element={<SassSolutions />} />
          <Route path="technologies" element={<TechnologiesNew />} />
          <Route
            path="technologies/technologies-details"
            element={<TechnologiesDetails />}
          />
          <Route
            path="technologies/front-end-technology"
            element={<FrontEndTech />}
          />
          <Route path="career" element={<Career />} />
          <Route path="career/career-details" element={<CareerDetails />} />
          <Route path="career/job-details" element={<JobDetails />} />
          <Route path="why-us" element={<WhyUs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="client-inquiry-form" element={<CTAForm />} />
          <Route path="work-detail" element={<CaseStudy />} />
          <Route path="work-detail/mybyk" element={<MyByk />} />
          <Route path="hire-us" element={<HireUs />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </main>
    </Suspense>
  );
};

export default App;
