import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import "./HireYourTeam.scss";

import hireTeam1 from "../../../images/poster-img.png";
import hireTeamWebp1 from "../../../images/poster-img.webp";
import hireTeam2 from "../../../images/team-work-1-1.jpg";
import hireTeamWebp2 from "../../../images/team-work-1-1.webp";

const HireYourTeam = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section
      className="hire_your_team_sec sec_pt sec_pb"
      style={{ background: "#f9f9f9" }}
    >
      <div className="container">
        <div className="row align-items-center mb-4 mb-lg-5">
          <div className="col-md-8 col-12">
            <div className="heading_wrapper">
              <h2 className="sec_heading sec_heading-medium with_border">
                {props.title}
              </h2>
            </div>
            <div className="desc mb-3 mb-md-0">
              <p>{props.description}</p>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="hire_your_team-btn">
              <Link to="/contact" className="btn btn_secondary">
                {props.linkName}
              </Link>
            </div>
          </div>
        </div>
        <div className="row custom_row_margin-lg">
          <div className="col-md-8 col-12 custom_col_padding-lg">
            <div className="hire_your_team_video">
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="rMWIhiYFsZU"
                onClose={() => setOpen(false)}
              />
              <div
                className="hire_your_team_video_wrapper"
                onClick={() => setOpen(true)}
              >
                <div
                  className="hire_your_team_video-img bg-overlay"
                >
                  <picture className="bg-overlay__media">
                    <source
                      srcSet={hireTeamWebp1}
                      type="image/webp"
                    />
                    <source
                      srcSet={hireTeam1}
                      type="image/png"
                    />
                    <Image
                      src={hireTeam1}
                      alt="footer"
                      fluid
                    />
                  </picture>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 custom_col_padding-lg">
            <div className="hire_your_team_img_block mt-4 mt-md-0">
              <div
                className="hire_your_team_img"
              >
                <picture className="bg-overlay__media">
                  <source
                    srcSet={hireTeamWebp2}
                    type="image/webp"
                  />
                  <source
                    srcSet={hireTeam2}
                    type="image/jpeg"
                  />
                  <Image
                    src={hireTeam2}
                    alt="footer"
                    fluid
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HireYourTeam;
