import React from "react";
import Slider from "react-slick";
import clientLogoData from "../../ComponentAPI/clientLogosAPI";
import "./ClientLogos.scss";

const ClientLogos = (props) => {
  var sliderSettings = {
    className: "client-logos__slider",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 7,
    autoplay: true,
    touchThreshold: 30,
    autoplaySpeed: 3000,
    speed: 2000,
    rows: 2,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          centerPadding: "30",
          centerMode: true,
        },
      },
    ],
  };
  return (
    <section className="client-logos pt-0">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading_wrapper">
              <h2 className="sec_heading sec_heading-medium with_border">
                {props.title}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <Slider {...sliderSettings}>
          {clientLogoData.map((val, index) => {
            return (
              <div className="client-logo__item" key={index}>
                <img src={val.image} className="img-fluid" alt="" loading="lazy" />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default ClientLogos;
