import React from "react";

const HeroImg = () => {
  return (
    <svg width="563" height="511" viewBox="0 0 563 511" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g className="animate-fade-up-down">
        <path
            d="M135.8 82.9001C135.8 86.3001 138.2 90.5 141.2 92.2L160.4 103.3C163.4 105 165.8 103.6 165.8 100.2V78C165.8 74.6 163.4 70.4 160.4 68.7L141.2 57.6C138.2 55.9 135.8 57.3001 135.8 60.7001V82.9001Z"
            fill="#FE769C"></path>
        <path
            d="M165.8 100.2V78C165.8 74.6 163.4 70.4 160.4 68.7L141.2 57.6C139.9 56.8 138.7 56.7 137.8 57.1L141 55.1C142 54.5 143.4 54.5 144.9 55.4L164.1 66.5C167.1 68.2 169.5 72.4001 169.5 75.8001V98C169.5 99.7001 168.9 101 167.8 101.5L164.2 103.6C165.2 103 165.8 101.8 165.8 100.2Z"
            fill="#FF2363"></path>
        <path
            d="M141.7 77.2001V75.1001C142.1 75.3001 142.4 75.4001 142.6 75.4001C142.8 75.4001 143 75.3001 143.1 75.1001C143.2 74.9001 143.3 74.7001 143.4 74.3001C143.4 74.0001 143.5 73.6001 143.5 72.8001C143.5 71.6001 143.5 70.9001 143.6 70.5001C143.7 70.1001 143.9 69.8001 144.1 69.7001C144.3 69.6001 144.7 69.5001 145.2 69.7001C145.5 69.8001 146 70.0001 146.7 70.4001L147.1 70.6001V72.7001C146.5 72.4001 146.2 72.2001 146 72.2001C145.8 72.2001 145.7 72.2001 145.6 72.3001C145.5 72.4001 145.5 72.6001 145.5 73.0001C145.5 73.4001 145.5 74.1001 145.4 75.1001C145.4 75.7001 145.3 76.1001 145.2 76.4001C145.1 76.7001 145 76.9001 144.8 77.1001C144.6 77.2001 144.4 77.3001 144 77.4001C144.3 77.8001 144.6 78.2001 144.7 78.5001C144.9 78.9001 145 79.3001 145.1 79.7001C145.2 80.1001 145.3 80.7001 145.3 81.4001C145.3 82.4001 145.4 83.0001 145.4 83.3001C145.4 83.7001 145.4 84.0001 145.5 84.2001C145.6 84.4001 145.7 84.6001 145.9 84.8001C146.1 85.0001 146.4 85.2001 147 85.5001V87.6001L146.6 87.4001C145.9 87.0001 145.4 86.6001 145 86.3001C144.6 86.0001 144.3 85.6001 144.1 85.1001C143.8 84.7001 143.7 84.2001 143.6 83.7001C143.5 83.2001 143.5 82.5001 143.5 81.6001C143.5 80.5001 143.5 79.8001 143.4 79.4001C143.3 78.9001 143.1 78.4001 142.9 78.1001C142.5 77.8001 142.2 77.5001 141.7 77.2001Z"
            fill="white"></path>
        <path
            d="M159.5 87.5C159.1 87.3 158.8 87.2 158.7 87.2C158.5 87.2 158.3 87.3 158.2 87.5C158.1 87.7 158 87.9 157.9 88.3C157.9 88.6 157.8 89 157.8 89.7C157.8 90.9 157.8 91.6 157.7 92C157.6 92.4 157.4 92.7 157.2 92.8C157 92.9 156.6 92.9 156.1 92.8C155.8 92.7 155.3 92.5 154.6 92.1L154.2 91.9V89.8C154.8 90.1 155.1 90.3 155.3 90.3C155.5 90.3 155.6 90.3 155.7 90.2C155.8 90.1 155.8 89.9 155.8 89.5C155.8 89.1 155.8 88.5 155.9 87.5C155.9 86.9 156 86.4 156.1 86.1C156.2 85.8 156.4 85.6 156.6 85.4C156.8 85.2 157 85.2 157.3 85.1C156.9 84.6 156.6 84.2 156.5 83.8C156.3 83.3 156.1 82.7 156 82C155.9 81.5 155.9 80.6 155.9 79.2C155.9 78.8 155.9 78.4 155.8 78.2C155.7 78 155.6 77.8 155.5 77.7C155.3 77.5 155 77.3 154.4 76.9V74.8L154.8 75C155.5 75.4 156 75.8 156.4 76.1C156.8 76.4 157.1 76.8 157.3 77.3C157.6 77.7 157.7 78.2 157.8 78.7C157.9 79.2 157.9 79.9 157.9 80.8C157.9 81.9 157.9 82.6 158 83C158.1 83.5 158.3 84 158.5 84.3C158.7 84.6 159.1 85 159.6 85.3V87.5H159.5Z"
            fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path
            d="M189.7 137C189.7 140.4 192.1 144.6 195.1 146.3L214.3 157.4C217.3 159.1 219.7 157.7 219.7 154.3V132.1C219.7 128.7 217.3 124.5 214.3 122.8L195.1 111.7C192.1 110 189.7 111.4 189.7 114.8V137Z"
            fill="#FC734A"></path>
        <path
            d="M219.7 154.3V132.1C219.7 128.7 217.3 124.5 214.3 122.8L195.1 111.7C193.8 110.9 192.6 110.8 191.7 111.2L194.9 109.2C195.9 108.6 197.3 108.6 198.8 109.5L218 120.6C221 122.3 223.4 126.5 223.4 129.9V152.1C223.4 153.9 222.8 155.1 221.7 155.6L218.1 157.7C219.1 157.1 219.7 155.9 219.7 154.3Z"
            fill="#D64D24"></path>
        <path
            d="M193.1 135.2V122.5L196 124.2C197.1 124.8 197.8 125.3 198.1 125.6C198.6 126.1 199 126.7 199.4 127.6C199.7 128.4 199.9 129.4 199.9 130.4C199.9 131.2 199.8 131.8 199.6 132.2C199.4 132.6 199.2 132.9 198.8 133C198.5 133.1 198.2 133.2 197.9 133.1C197.5 133 196.9 132.7 196 132.2L194.8 131.5V136.3L193.1 135.2ZM194.9 125.7V129.3L195.9 129.9C196.6 130.3 197.1 130.5 197.3 130.5C197.5 130.5 197.7 130.4 197.9 130.2C198 130 198.1 129.7 198.1 129.3C198.1 128.8 198 128.4 197.8 128C197.6 127.6 197.4 127.3 197.1 127C196.9 126.8 196.5 126.5 195.8 126.2L194.9 125.7Z"
            fill="white"></path>
        <path
            d="M201.4 140V127.3L203.2 128.3V133.3L206.7 135.3V130.3L208.5 131.3V144L206.7 143V137.4L203.2 135.4V141L201.4 140Z"
            fill="white"></path>
        <path
            d="M210.3 145.2V132.5L213.2 134.2C214.3 134.8 215 135.3 215.3 135.6C215.8 136.1 216.2 136.7 216.6 137.6C216.9 138.4 217.1 139.4 217.1 140.4C217.1 141.2 217 141.8 216.8 142.2C216.6 142.6 216.3 142.9 216 143C215.7 143.1 215.4 143.2 215.1 143.1C214.7 143 214.1 142.7 213.2 142.2L212 141.5V146.3L210.3 145.2ZM212.1 135.6V139.2L213.1 139.8C213.8 140.2 214.3 140.4 214.5 140.4C214.7 140.4 214.9 140.3 215.1 140.1C215.2 139.9 215.3 139.6 215.3 139.2C215.3 138.7 215.2 138.3 215 137.9C214.8 137.5 214.6 137.2 214.3 136.9C214.1 136.7 213.7 136.4 213 136.1L212.1 135.6Z"
            fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path
            d="M483.7 199C483.7 202.4 486.1 206.6 489.1 208.3L508.3 219.4C511.3 221.1 513.7 219.7 513.7 216.3V194.1C513.7 190.7 511.3 186.5 508.3 184.8L489 173.7C486 172 483.6 173.4 483.6 176.8V199H483.7Z"
            fill="#27ABB0"></path>
        <path
            d="M513.6 216.3V194.1C513.6 190.7 511.2 186.5 508.2 184.8L489 173.7C487.7 172.9 486.5 172.8 485.6 173.2L488.8 171.2C489.8 170.6 491.2 170.6 492.7 171.5L511.9 182.6C514.9 184.3 517.3 188.5 517.3 191.9V214.1C517.3 215.8 516.7 217.1 515.6 217.6L512 219.7C513.1 219.2 513.6 218 513.6 216.3Z"
            fill="#018489"></path>
        <path
            d="M492.3 194.8L494 196.5C493.7 197.6 493.3 198.3 492.7 198.5C492.1 198.8 491.3 198.6 490.4 198.1C489.3 197.4 488.3 196.4 487.6 194.9C486.9 193.4 486.5 191.8 486.5 190C486.5 188.1 486.9 186.8 487.6 186.2C488.3 185.6 489.3 185.6 490.5 186.3C491.5 186.9 492.4 187.8 493 189C493.4 189.7 493.7 190.6 493.9 191.6L492.2 191.2C492.1 190.5 491.9 190 491.6 189.4C491.3 188.9 490.9 188.5 490.4 188.2C489.8 187.8 489.3 187.8 488.9 188.2C488.5 188.6 488.3 189.4 488.3 190.8C488.3 192.2 488.5 193.4 488.9 194.2C489.3 195 489.8 195.6 490.4 196C490.9 196.3 491.3 196.3 491.6 196.1C491.9 196 492.1 195.5 492.3 194.8Z"
            fill="white"></path>
        <path
            d="M495 196.8L496.7 197.6C496.8 198.4 497 199.1 497.3 199.6C497.6 200.1 498 200.5 498.6 200.9C499.2 201.2 499.6 201.3 499.9 201.2C500.2 201 500.3 200.8 500.3 200.3C500.3 200 500.2 199.8 500.1 199.5C500 199.2 499.8 199 499.5 198.6C499.3 198.4 498.8 198 498.1 197.3C497.2 196.5 496.5 195.7 496.2 195C495.7 194.1 495.4 193.2 495.4 192.2C495.4 191.6 495.5 191.1 495.8 190.8C496 190.4 496.4 190.3 496.9 190.3C497.4 190.3 497.9 190.5 498.6 190.9C499.7 191.5 500.5 192.3 501 193.2C501.5 194.1 501.8 195.1 501.9 196.2L500.2 195.3C500.1 194.7 500 194.2 499.7 193.8C499.5 193.4 499.1 193.1 498.6 192.8C498.1 192.5 497.7 192.4 497.4 192.5C497.2 192.6 497.1 192.8 497.1 193.1C497.1 193.4 497.2 193.6 497.4 193.9C497.6 194.3 498.1 194.8 499 195.6C499.8 196.4 500.5 197 500.9 197.5C501.3 198 501.6 198.6 501.8 199.2C502 199.8 502.1 200.5 502.1 201.2C502.1 201.9 502 202.4 501.7 202.8C501.4 203.2 501 203.4 500.5 203.4C500 203.4 499.4 203.2 498.6 202.7C497.5 202.1 496.7 201.3 496.1 200.3C495.4 199.3 495.1 198.2 495 196.8Z"
            fill="white"></path>
        <path
            d="M502.9 201.4L504.6 202.2C504.7 203 504.9 203.7 505.2 204.2C505.5 204.7 505.9 205.1 506.5 205.5C507.1 205.8 507.5 205.9 507.8 205.8C508.1 205.6 508.2 205.4 508.2 204.9C508.2 204.6 508.1 204.4 508 204.1C507.9 203.8 507.7 203.6 507.4 203.2C507.2 203 506.7 202.6 506 201.9C505.1 201.1 504.4 200.3 504.1 199.6C503.6 198.7 503.3 197.8 503.3 196.8C503.3 196.2 503.4 195.7 503.7 195.4C503.9 195 504.3 194.9 504.8 194.9C505.3 194.9 505.8 195.1 506.5 195.5C507.6 196.1 508.4 196.9 508.9 197.8C509.4 198.7 509.7 199.7 509.8 200.8L508.1 199.9C508 199.3 507.9 198.8 507.6 198.4C507.4 198 507 197.7 506.5 197.4C506 197.1 505.6 197 505.3 197.1C505.1 197.2 505 197.4 505 197.6C505 197.9 505.1 198.1 505.3 198.4C505.5 198.8 506 199.3 506.9 200.1C507.7 200.9 508.4 201.5 508.8 202C509.2 202.5 509.5 203.1 509.7 203.7C509.9 204.3 510 205 510 205.7C510 206.4 509.9 206.9 509.6 207.3C509.3 207.7 508.9 207.9 508.4 207.9C507.9 207.9 507.3 207.7 506.5 207.2C505.4 206.6 504.6 205.8 504 204.8C503.4 203.9 503.1 202.8 502.9 201.4Z"
            fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path opacity="0.7"
            d="M102 411.8C102 415.2 104.4 419.5 107.4 421.2L126.8 432.4C129.8 434.1 132.2 432.7 132.2 429.3V406.9C132.2 403.5 129.8 399.2 126.8 397.5L107.4 386.3C104.4 384.6 102 386 102 389.4V411.8Z"
            fill="#005FF1"></path>
        <path
            d="M132.2 429.2V406.8C132.2 403.4 129.8 399.1 126.8 397.4L107.4 386.2C106.1 385.4 104.9 385.3 103.9 385.7L107.2 383.7C108.2 383.1 109.6 383.1 111.1 384L130.5 395.2C133.5 396.9 135.9 401.1 135.9 404.6V427C135.9 428.8 135.3 430 134.2 430.5L130.6 432.6C131.7 432.1 132.2 430.9 132.2 429.2Z"
            fill="#005FF1"></path>
        <path
            d="M112.7 400.3L114.7 401.5V408.9C114.7 409.9 114.6 410.6 114.5 411C114.3 411.6 113.9 411.9 113.4 412C112.9 412.1 112.2 411.9 111.3 411.4C110.3 410.8 109.5 410.1 109 409.1C108.5 408.1 108.2 407 108.2 405.8L110.1 406.7C110.1 407.4 110.2 407.9 110.4 408.3C110.6 408.9 111 409.3 111.4 409.5C111.9 409.8 112.2 409.8 112.4 409.6C112.6 409.4 112.7 408.8 112.7 407.9V400.3Z"
            fill="white"></path>
        <path
            d="M116.2 411.6L118.2 412.4C118.3 412.9 118.5 413.3 118.7 413.7C119 414.1 119.3 414.4 119.8 414.7C120.3 415 120.7 415.1 120.9 415C121.1 414.9 121.2 414.8 121.2 414.5C121.2 414.3 121.2 414.2 121.1 414C121 413.8 120.8 413.6 120.4 413.3C118.8 412 117.8 411.1 117.4 410.5C116.8 409.7 116.5 408.9 116.5 408.1C116.5 407.4 116.8 406.9 117.3 406.7C117.8 406.5 118.6 406.7 119.6 407.3C120.6 407.9 121.4 408.5 121.8 409.2C122.3 409.9 122.6 410.6 122.8 411.5L121 410.8C120.9 410.4 120.8 410.1 120.6 409.8C120.4 409.5 120.1 409.2 119.7 409C119.2 408.7 118.8 408.6 118.6 408.6C118.5 408.6 118.4 408.7 118.4 408.9C118.4 409.1 118.5 409.2 118.6 409.4C118.8 409.7 119.4 410.2 120.4 411.1C121.4 412 122.2 412.7 122.6 413.4C123 414 123.2 414.7 123.2 415.4C123.2 416.2 122.9 416.7 122.4 416.9C121.8 417.1 121 416.9 119.9 416.3C118.9 415.7 118.1 415 117.5 414.2C116.8 413.3 116.4 412.5 116.2 411.6Z"
            fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path
            d="M390.5 147.4C390.5 150.8 392.9 155 395.9 156.7L415.1 167.8C418.1 169.5 420.5 168.1 420.5 164.7V142.5C420.5 139.1 418.1 134.9 415.1 133.2L395.9 122.1C392.9 120.4 390.5 121.8 390.5 125.2V147.4Z"
            fill="#FEBF3E"></path>
        <path
            d="M420.4 164.7V142.5C420.4 139.1 418 134.9 415 133.2L395.8 122.1C394.5 121.3 393.3 121.2 392.4 121.6L395.6 119.6C396.6 119 398 119 399.5 119.9L418.7 131C421.7 132.7 424.1 136.9 424.1 140.3V162.5C424.1 164.2 423.5 165.5 422.4 166L418.8 168.1C419.9 167.6 420.4 166.4 420.4 164.7Z"
            fill="#FE9800"></path>
        <path
            d="M393.3 142.9V133.2L394.6 134V137.8L397.2 139.3V135.5L398.5 136.3V146L397.2 145.2V140.9L394.6 139.4V143.7L393.3 142.9Z"
            fill="white"></path>
        <path d="M401.3 147.5V139.4L399.4 138.3V136.6L404.6 139.6V141.2L402.7 140.1V148.2L401.3 147.5Z" fill="white">
        </path>
        <path
            d="M405.4 149.8V140.1L407.4 141.2L408.6 148.5L409.8 142.5L411.8 143.6V153.3L410.6 152.6V144.9L409.3 151.8L408 151.1L406.7 142.7V150.4L405.4 149.8Z"
            fill="white"></path>
        <path d="M413 154.2V144.5L414.3 145.3V153.3L417.6 155.2V156.8L413 154.2Z" fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path opacity="0.7"
            d="M257.9 48.8C257.9 52.2 260.3 56.5 263.3 58.2L282.7 69.4C285.7 71.1 288.1 69.7 288.1 66.3V43.9C288.1 40.5 285.7 36.2 282.7 34.5L263.3 23.3C260.3 21.6 257.9 23 257.9 26.4V48.8Z"
            fill="#005FF1"></path>
        <path
            d="M288.1 66.3V43.9C288.1 40.5 285.7 36.2 282.7 34.5L263.3 23.3C262 22.5 260.8 22.4 259.8 22.8L263.1 20.8C264.1 20.2 265.5 20.2 267 21.1L286.4 32.3C289.4 34 291.8 38.2 291.8 41.7V64.1C291.8 65.9 291.2 67.1 290.1 67.6L286.5 69.7C287.5 69.1 288.1 67.9 288.1 66.3Z"
            fill="#005FF1"></path>
        <path
            d="M280.5 42.9C277.8 38.2 273.3 35.6 270.6 37.2C268 38.7 267.9 43.3 270.2 47.8L269 48.5L268.5 47.6L263 50.8L265 54.2L270.5 51L270 50.1L271.2 49.4C274 53.6 278 55.8 280.6 54.3C283.2 52.8 283.2 47.7 280.5 42.9ZM279.2 52.2C277.2 53.4 273.9 51.5 271.8 47.9C269.8 44.4 269.8 40.6 271.8 39.4C273.8 38.2 277.1 40.1 279.2 43.7C281.3 47.2 281.3 51 279.2 52.2Z"
            fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path
            d="M57.3999 333.9C57.3999 337.3 59.7999 341.6 62.7999 343.3L82.1999 354.5C85.1999 356.2 87.5999 354.8 87.5999 351.4V329C87.5999 325.6 85.1999 321.3 82.1999 319.6L62.7999 308.4C59.7999 306.7 57.3999 308.1 57.3999 311.5V333.9Z"
            fill="#FEBF3E"></path>
        <path
            d="M87.5998 351.3V329C87.5998 325.6 85.1998 321.3 82.1998 319.6L62.7998 308.4C61.4998 307.6 60.2998 307.5 59.2998 307.9L62.5998 305.9C63.5998 305.3 64.9998 305.3 66.4998 306.2L85.9998 317.3C88.9998 319 91.3998 323.2 91.3998 326.7V349.1C91.3998 350.9 90.7998 352.1 89.6998 352.6L86.0998 354.7C87.0998 354.2 87.5998 353 87.5998 351.3Z"
            fill="#FE9800"></path>
        <path
            d="M79.2998 333.5L78.1998 332.9C77.7998 332.7 77.3998 332.2 77.2998 331.9C77.1998 331.6 77.1998 330.6 77.4998 330.4L78.1998 330C78.4998 329.8 78.4998 329.3 78.1998 328.8L76.9998 326.7C76.6998 326.2 76.1998 325.9 75.8998 326.1L75.1998 326.5C74.8998 326.7 74.4998 326.6 74.2998 326.3C74.0998 326 73.3998 325 73.3998 324.5V323.3C73.3998 322.8 73.0998 322.2 72.5998 322L70.8998 321C70.4998 320.8 70.0998 321 70.0998 321.4V322.6C70.0998 323.1 69.8998 323.4 69.6998 323.4C69.4998 323.4 68.5998 322.9 68.2998 322.4L67.5998 321.1C67.2998 320.6 66.7998 320.3 66.4998 320.5L65.2998 321.2C64.9998 321.4 64.9998 321.9 65.2998 322.4L65.9998 323.7C66.2998 324.2 66.3998 324.8 66.2998 325C66.1998 325.2 65.5998 325.6 65.1998 325.4L64.0998 324.8C63.6998 324.6 63.2998 324.8 63.2998 325.2V327.2C63.2998 327.7 63.5998 328.3 64.0998 328.5L65.1998 329.1C65.5998 329.3 65.9998 329.8 66.0998 330.1C66.1998 330.4 66.1998 331.4 65.8998 331.6L65.0998 332C64.7998 332.2 64.7998 332.7 65.0998 333.2L66.2998 335.3C66.5998 335.8 67.0998 336.1 67.3998 335.9L68.0998 335.5C68.3998 335.3 68.7998 335.4 68.9998 335.7C69.1998 336 69.8998 337 69.8998 337.4V338.6C69.8998 339.1 70.1998 339.7 70.6998 339.9L72.3998 340.9C72.7998 341.1 73.1998 340.9 73.1998 340.5V339.3C73.1998 338.8 73.3998 338.5 73.5998 338.5C73.7998 338.5 74.6998 339 74.9998 339.5L75.6998 340.8C75.9998 341.3 76.4998 341.6 76.7998 341.4L77.9998 340.7C78.2998 340.5 78.2998 340 77.9998 339.5L77.2998 338.2C76.9998 337.7 76.8998 337.1 76.9998 336.9C77.0998 336.7 77.6998 336.3 78.0998 336.5L79.1998 337.1C79.5998 337.3 79.9998 337.1 79.9998 336.7V334.7C79.9998 334.3 79.6998 333.7 79.2998 333.5ZM71.6998 335.4C69.5998 334.2 67.8998 331.2 67.8998 328.8C67.8998 326.4 69.5998 325.4 71.6998 326.6C73.7998 327.8 75.4998 330.8 75.4998 333.2C75.4998 335.6 73.7998 336.6 71.6998 335.4Z"
            fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path
            d="M309.7 457.8C309.7 461.2 312.1 465.5 315.1 467.2L334.5 478.4C337.5 480.1 339.9 478.7 339.9 475.3V452.9C339.9 449.5 337.5 445.2 334.5 443.5L315.1 432.3C312.1 430.6 309.7 432 309.7 435.4V457.8Z"
            fill="#FC734A"></path>
        <path
            d="M340 475.3V452.9C340 449.5 337.6 445.2 334.6 443.5L315.2 432.3C313.9 431.5 312.7 431.4 311.7 431.8L315 429.8C316 429.2 317.4 429.2 318.9 430.1L338.3 441.3C341.3 443 343.7 447.2 343.7 450.7V473.1C343.7 474.9 343.1 476.1 342 476.6L338.4 478.7C339.4 478.2 340 477 340 475.3Z"
            fill="#D64D24"></path>
        <path d="M320 459.7L313.8 451V448.1L320 446.5V449.9L315.7 450.6L320 456.4V459.7Z" fill="white"></path>
        <path d="M321.9 463.1L324.1 446.5L326.1 447.7L323.8 464.3L321.9 463.1Z" fill="white"></path>
        <path d="M327.8 464.2V460.9L332.1 460.1L327.8 454.4V451.1L334 459.8V462.6L327.8 464.2Z" fill="white"></path>
    </g>
    <g className="animate-fade-up-down">
        <path
            d="M450.5 297.9C450.5 301.3 452.9 305.5 455.9 307.2L475.1 318.3C478.1 320 480.5 318.6 480.5 315.2V293C480.5 289.6 478.1 285.4 475.1 283.7L455.9 272.6C452.9 270.9 450.5 272.3 450.5 275.7V297.9Z"
            fill="#FE769C"></path>
        <path
            d="M480.5 315.2V293C480.5 289.6 478.1 285.4 475.1 283.7L455.9 272.6C454.6 271.8 453.4 271.7 452.5 272.1L455.7 270.1C456.7 269.5 458.1 269.5 459.6 270.4L478.8 281.5C481.8 283.2 484.2 287.4 484.2 290.8V313C484.2 314.7 483.6 316 482.5 316.5L478.9 318.6C479.9 318.1 480.5 316.9 480.5 315.2Z"
            fill="#FF2363"></path>
        <path
            d="M460.2 295.9L462 297.7C461.7 299 461.3 299.8 460.6 300.1C460 300.4 459.2 300.3 458.2 299.8C457 299.1 456 297.9 455.2 296.3C454.4 294.7 454 292.8 454 290.7C454 288.5 454.4 287 455.2 286.3C456 285.5 457 285.5 458.3 286.3C459.4 286.9 460.3 287.9 461 289.3C461.4 290.1 461.7 291.1 461.9 292.3L460.1 291.9C460 291.2 459.8 290.5 459.4 289.9C459.1 289.3 458.6 288.9 458.2 288.6C457.5 288.2 457 288.2 456.6 288.7C456.2 289.2 456 290.2 456 291.7C456 293.4 456.2 294.7 456.6 295.6C457 296.5 457.5 297.2 458.2 297.6C458.7 297.9 459.1 297.9 459.5 297.6C459.8 297.4 460 296.8 460.2 295.9Z"
            fill="white"></path>
        <path
            d="M465.5 302V298.6L463.1 297.2V294.8L465.5 296.2V292.8L467.1 293.7V297.1L469.5 298.5V300.9L467.1 299.5V302.9L465.5 302Z"
            fill="white"></path>
        <path
            d="M472.9 306.2V302.8L470.5 301.4V299L472.9 300.4V297L474.5 297.9V301.3L476.9 302.7V305.1L474.5 303.7V307.1L472.9 306.2Z"
            fill="white"></path>
    </g>
</svg>
  );
};

export default HeroImg;
