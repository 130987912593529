import React from "react";
// import { useState } from 'react';
import CountUp from "react-countup";
// import { Link } from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";
import BgImg from "../../images/bg-statistics.jpg";
import "./statistics.scss";

import {
  statisticsHomeData,
  techListData,
} from "../../ComponentAPI/statisticsAPI";

const Statistics = (props) => {
  const [focus, setFocus] = React.useState(false);
  return (
    <section
      className="statistics bg-fixed bg-overlay"
      style={{ backgroundImage: "url(" + BgImg + ")" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-5 col-lg-5">
            <div className="statistics__content text-light">
              <div className="heading_wrapper">
                <h2 className="sec_heading sec_heading-medium with_border text-light">
                  {props.title}
                </h2>
              </div>
              <p>{props.description}</p>
              {/* <div className="btn-wrapper">
                                    <Link to="/about" className="btn btn_secondary">{props.linkName}</Link>
                                </div> */}

              <ul className="statistics__tech-list list-unstyled">
                {techListData.map((val, index) => {
                  return (
                    <li key={index}>
                      <img
                        src={val.img}
                        className="img-fluid"
                        alt={val.title}
                        height={"100%"}
                        width={"100%"}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="col-md-7 col-lg-6 offset-lg-1">
            <div className="statistics__boxes">
              {statisticsHomeData.map((val, index) => {
                return (
                  <div className={"statistics__box"} key={index}>
                    <div className="statistics__box-inner">
                      <CountUp
                        start={focus ? 0 : null}
                        end={val.countNum}
                        duration={3}
                        suffix={val.suffix}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span
                              className="statistics__count"
                              ref={countUpRef}
                            />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <h3 className="statistics__title">{val.title}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statistics;
